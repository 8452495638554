import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type State = {
  activeMainNavItem: string | null;
  activeSubNavItem: string | null;
  currentOpenedSubNav: string | null | undefined;
  currentGroup: string | null;
  currentPageMainNavId: string | null;
  currentPageSubNavId: string | null;
  mobileNavOpen: boolean;
  isMobileNav: boolean;
  setActiveMainNavItem: (value: string | null) => void;
  setActiveSubNavItem: (value: string | null) => void;
  setCurrentOpenedSubNav: (value: string | null | undefined) => void;
  setCurrentGroup: (value: string | null) => void;
  setCurrentPageMainNavId: (value: string | null) => void;
  setCurrentPageSubNavId: (value: string | null) => void;
  setMobileNavOpen: (value: boolean) => void;
  setIsMobileNav: (value: boolean) => void;
};

const NavState = createWithEqualityFn<State>()(
  (set) => ({
    activeMainNavItem: null,
    activeSubNavItem: null,
    currentOpenedSubNav: null,
    currentGroup: null,
    currentPageMainNavId: null,
    currentPageSubNavId: null,
    mobileNavOpen: false,
    isMobileNav: true,
    setActiveMainNavItem: (value: string | null) =>
      set({ activeMainNavItem: value }),
    setActiveSubNavItem: (value: string | null) =>
      set({ activeSubNavItem: value }),
    setCurrentOpenedSubNav: (value: string | null | undefined) =>
      set({ currentOpenedSubNav: value }),
    setCurrentGroup: (value: string | null) => set({ currentGroup: value }),
    setCurrentPageMainNavId: (value: string | null) =>
      set({ currentPageMainNavId: value }),
    setCurrentPageSubNavId: (value: string | null) =>
      set({ currentPageSubNavId: value }),
    setMobileNavOpen: (value: boolean) => set({ mobileNavOpen: value }),
    setIsMobileNav: (value: boolean) => set({ isMobileNav: value }),
  }),
  shallow,
);

export default NavState;
