import { PortableTextRenderProps } from '../../PortableText.types';
import { PortableTextConfigListItem } from '../../PortableTextConfig.types';
import BulletListItem from './BulletListItem/BulletListItem';
import NumberListItem from './NumberListItem/NumberListItem';

const configListItem: PortableTextConfigListItem = {
  bullet: {
    render: (value: PortableTextRenderProps) => (
      <BulletListItem {...value}>{value.children}</BulletListItem>
    ),
  },
  number: {
    render: (value: PortableTextRenderProps) => (
      <NumberListItem {...value}>{value.children}</NumberListItem>
    ),
  },
};

export default configListItem;
