import { ReactNode } from 'react';

import { BaseButtonProps } from '../ButtonBase.types';

// If we don't have window.drift after 5 retries, we assume the client won't load Drift at all
export const DRIFT_MAX_RETRIES = 10;
// Interval in ms
export const DRIFT_POLL_INTERVAL = 500;

export type CMSDriftButtonProps = {
  driftConfiguration: {
    /**
     * Link for when Drift is available in the window
     */
    interactionId: number;
    /**
     * Link for when Drift is not available in the window
     */
    fallbackLink: string;
    customTrackingEvent?: string;
  };
  label?: string;
  customTrackingEvent?: string;
};

export type DriftButtonProps = CMSDriftButtonProps &
  BaseButtonProps & {
    children?: ReactNode;
  };
