import { PortableTextConfigBlock } from '../../PortableTextConfig.types';

const configBlock: PortableTextConfigBlock = {
  accents: {
    label: {
      tagName: 'span',
    },
    eyebrowSmall: {
      tagName: 'span',
    },
    eyebrow: {
      tagName: 'span',
    },
  },
  bodies: {
    body: {
      tagName: 'p',
    },
    footnote: {
      tagName: 'p',
    },
    ribbon: {
      tagName: 'span',
    },
    formDisclaimer: {
      tagName: 'p',
    },
  },
  titles: {
    title1: {
      tagName: 'h1',
    },
    title2: {
      tagName: 'h2',
    },
    title3: {
      tagName: 'h3',
    },
    title4: {
      tagName: 'h4',
    },
    title5: {
      tagName: 'h5',
    },
    title55: {
      tagName: 'h5',
    },
    title6: {
      tagName: 'h6',
    },
  },
};

export default configBlock;
