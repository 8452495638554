const isEmptySpace = (word: string) => {
  return word === ' ' || word.length === 0;
};

const handleSplitLetters = (text: string) => {
  const letters: string[] = [];
  handleSplitWords(text).map((word: string) => {
    if (!isEmptySpace(word)) {
      word.split('').map((letter) => letters.push(letter));
    }
  });

  return letters;
};

const handleSplitWords = (text: string) => {
  if (typeof text !== 'string') return [];
  return text
    .trim()
    .split(' ')
    .reduce((array: string[], b) => [...array, b, ' '], [])
    .map((word: string) => word);
};

export { handleSplitWords, handleSplitLetters, isEmptySpace };
