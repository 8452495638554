import addToRefArray from '~/utils/addToRefArray';

import { PortableTextRenderProps } from '../../../PortableText.types';
import styles from './BulletListItem.module.css';

/**
 * Renders an <li> within a Bullet <ul>
 * Note that children will be rendered as BlockBase by default, or their respective component wrappers.
 */

const BulletListItem = ({ children, refs }: PortableTextRenderProps) => {
  return (
    <li
      className={styles.listItemUl}
      ref={(element: HTMLLIElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {children}
    </li>
  );
};

export default BulletListItem;
