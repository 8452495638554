import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { PortableTextRenderProps } from '../../../PortableText.types';

const OpenQuote = (props: PortableTextRenderProps) => {
  const { className, refs, children } = props;

  return (
    <span
      className={cn(styles.openQuote, className)}
      ref={(element: HTMLElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {children}
    </span>
  );
};

export default OpenQuote;
