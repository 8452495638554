import SvgAdobeLogo from '~/assets/svg/logo-adobe.svg';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import styles from './AdobeLogo.module.css';

type Props = {
  className?: string;
};

const AdobeLogo = ({ className }: Props) => {
  return (
    <a
      href="https://www.adobe.com"
      target="_blank"
      rel="noreferrer"
      className={cn(styles.adobeLogo, className)}
      title={dict('navLinkAdobe')}
      aria-label={dict('navLinkAdobe')}
    >
      <SvgAdobeLogo viewBox={'0 0 19 16'} />
    </a>
  );
};

export default AdobeLogo;
