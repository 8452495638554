import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { PortableTextRenderProps } from '../../../PortableText.types';

const Strong = ({ className, refs, children }: PortableTextRenderProps) => {
  return (
    <strong
      className={cn(styles.strong, className)}
      ref={(element: HTMLElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {children}
    </strong>
  );
};

export default Strong;
