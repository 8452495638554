import styles from '~/components/molecules/SplitText/SplitText.module.css';
import { SplitTextProps } from '~/components/molecules/SplitText/SplitText.types';
import {
  handleSplitLetters,
  handleSplitWords,
  isEmptySpace,
} from '~/components/molecules/SplitText/utils/handleSplitText';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

const SplitText = ({
  wordRefs,
  letterRefs,
  index,
  groupStyleKey,
  category,
  trim,
  parentRef,
  markType,
  children,
}: SplitTextProps) => {
  const splitLetters = typeof letterRefs !== 'undefined';
  const splitWords = typeof wordRefs !== 'undefined';

  const shouldSplit = splitWords || splitLetters;

  const text =
    typeof children === 'string' && shouldSplit
      ? handleSplitWords(children).map((word) => {
          return word;
        })
      : children;

  const renderLetters = (word: string) => {
    const letters = handleSplitLetters(word);
    return letters.map((letter, i) => {
      return (
        <span
          key={`letter-${i}`}
          className={styles.letter}
          ref={(element: HTMLElement) => {
            if (letterRefs) {
              if (element && parentRef) {
                return addToRefArray({
                  element: {
                    $el: element,
                    groupStyleKey: groupStyleKey,
                    index: index,
                    category: category,
                    parentRef: parentRef,
                    markType: markType,
                  },
                  refArray: letterRefs,
                });
              }
            }
          }}
        >
          {letter}
        </span>
      );
    });
  };

  return Array.isArray(text)
    ? text.map((word, i) => {
        return word && typeof word === 'string' && (wordRefs || letterRefs) ? (
          <span
            className={cn(isEmptySpace(word) ? styles.emptySpace : styles.word)}
            key={`word-${i}`}
            ref={(element: HTMLElement) => {
              if (wordRefs && !isEmptySpace(word)) {
                if (element && parentRef) {
                  return addToRefArray({
                    element: {
                      $el: element,
                      groupStyleKey: groupStyleKey,
                      index: index,
                      category: category,
                      parentRef: parentRef,
                      markType: markType,
                    },
                    refArray: wordRefs,
                  });
                }
              }
            }}
          >
            {isEmptySpace(word) && trim !== true
              ? ' '
              : splitLetters
                ? renderLetters(word)
                : word}
          </span>
        ) : (
          word
        );
      })
    : typeof text === 'string' && text;
};

export default SplitText;
