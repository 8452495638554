import { MouseEventHandler, useCallback, useEffect, useState } from 'react';

import { CMSLinkProps } from '~/components/atoms/Link/Link.types';

import {
  DRIFT_MAX_RETRIES,
  DRIFT_POLL_INTERVAL,
  DriftButtonProps,
} from './DriftButton.types';

const useDriftButton = (
  driftConfiguration: DriftButtonProps['driftConfiguration'],
): {
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  to?: CMSLinkProps;
} => {
  const [isDriftLoaded, updateIsDriftLoaded] = useState(false);

  useEffect(() => {
    if (!isDriftLoaded) {
      let retryNumber = 0;

      let timeoutId: ReturnType<typeof setTimeout>;

      const checkDrift = () => {
        retryNumber++;
        if (window && typeof window.drift !== 'undefined') {
          updateIsDriftLoaded(true);
        } else if (retryNumber <= DRIFT_MAX_RETRIES) {
          timeoutId = setTimeout(checkDrift, DRIFT_POLL_INTERVAL);
        }
      };

      // The first timeout is smaller to first check quickly if Drift loaded instantly
      timeoutId = setTimeout(checkDrift, 300);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isDriftLoaded, driftConfiguration]);

  const onClick = useCallback(() => {
    if (isDriftLoaded) {
      window.drift.api.startInteraction({
        interactionId: driftConfiguration.interactionId,
      });
    }
  }, [isDriftLoaded, driftConfiguration]);

  if (!driftConfiguration.fallbackLink) {
    return {};
  }

  let to: CMSLinkProps | undefined;

  if (!isDriftLoaded) {
    to = {
      type: 'external',
      url: driftConfiguration.fallbackLink,
    };
  }

  return { onClick, to };
};

export default useDriftButton;
