import { ComponentType } from 'react';

import {
  PortableTextBlockRecord,
  PortableTextComponentKey,
  PortableTextListItemRecord,
  PortableTextListRecord,
  PortableTextMarksRecord,
  PortableTextRenderProps,
  PortableTextTypesRecord,
  RenderRecord,
  RenderRecordFlat,
  RenderRecordGrouped,
} from '../PortableText.types';
import {
  PortableTextConfig,
  PortableTextConfigBlock,
  PortableTextConfigBlockType,
  PortableTextConfigFlatHierarchy,
  PortableTextConfigProp,
} from '../PortableTextConfig.types';
import {
  PortableTextOptions,
  PortableTextOptionsBlock,
  PortableTextOptionsBlockType,
  PortableTextOptionsFlatHierarchy,
} from '../PortableTextOptions.types';
import BlockBase from './block/BlockBase/BlockBase';
import getComponentProps from './getComponentProps';

/**
 * makeComponent() creates a rendering configuration file for @portabletext/react based on a module's
 * options and global configuration files. the actual data is not processed in makeComponent(), that is
 * part of the @portabletext/react lifecycle.
 * @param type component type (block, list, listItem, marks, types)
 * @param data sanity data
 * @param config global config files (see /PortableText/components/block/configBlock for example)
 * @param options individual module options from the `options` prop
 */

const makeComponent = (
  type: PortableTextComponentKey,
  data: PortableTextConfigProp,
  config: PortableTextConfig,
  options: PortableTextOptions,
): RenderRecord => {
  let renderRecord: RenderRecord;

  /**
   * Build the `render` prop being sent to @react/portabletext.
   * This uses getComponentProps() to process our custom logic and return an object that is PortableText
   * compatible.
   */
  const renderItem = (props: PortableTextRenderProps) => {
    const renderProps: PortableTextRenderProps | undefined = getComponentProps(
      type,
      config,
      options,
      props,
    );

    if (renderProps) {
      if (renderProps?.render) {
        // if render property is specified, use the specific component's `render` prop
        const CustomBlockRender =
          renderProps.render as ComponentType<PortableTextRenderProps>;

        return (
          <CustomBlockRender {...renderProps}>
            {props.children}
          </CustomBlockRender>
        );
      } else {
        // if no render property, default to BlockBase
        return <BlockBase {...renderProps}>{props.children}</BlockBase>;
      }
    }

    return <div>Rendering Error</div>;
  };

  /**
   * Empty stale refs.
   * A ref is essentially an array, so it needs to be cleared before being repopulated.
   * This prevents accidental duplicate refs in the case of a re-render.
   */

  const emptyStaleRef = (refsValue: unknown) => {
    if (refsValue && Array.isArray(refsValue)) {
      const refArray = refsValue as Element[];
      refArray.splice(0);
    }
  };

  const emptyStaleBlockRefs = () => {
    const blockOptions = options as PortableTextOptionsBlock;
    for (const optionGroup of Object.entries(blockOptions)) {
      // empty group ref (example: block.titles.ref)
      const optionValue = optionGroup[1];
      emptyStaleRef(optionValue?.ref?.current);

      // empty individual ref (example: block.titles.title2.ref)
      for (const [optionItemIterator, optionItemValue] of Object.entries(
        optionGroup,
      )) {
        const optionItemKey: keyof PortableTextOptionsBlockType =
          optionItemIterator as keyof PortableTextOptionsBlockType;
        if (
          optionItemKey !== 'className' &&
          optionItemKey !== 'tagName' &&
          optionItemKey !== 'ref'
        ) {
          const optionItemComponent = optionItemValue as PortableTextConfigProp;
          emptyStaleRef(optionItemComponent?.ref?.current);
        }
      }
    }
  };

  const emptyStaleFlatRefs = () => {
    const flatOptions = options as PortableTextOptionsFlatHierarchy;

    // empty type ref (example: marks.refs)
    emptyStaleRef(flatOptions?.ref?.current);

    // empty individual ref (example: marks.em.ref)
    for (const [optionItemIterator, optionItemValue] of Object.entries(
      flatOptions,
    )) {
      const optionItemKey: keyof PortableTextOptionsBlockType =
        optionItemIterator as keyof PortableTextOptionsBlockType;
      if (
        optionItemKey !== 'className' &&
        optionItemKey !== 'tagName' &&
        optionItemKey !== 'ref'
      ) {
        const optionItemComponent = optionItemValue as PortableTextConfigProp;
        emptyStaleRef(optionItemComponent?.ref?.current);
      }
    }
  };

  /**
   * No data, no block!
   * However, data will not be processed here - once the the renderRecord is returned to @react/portabletext,
   * it will be processed there.
   */
  if (data) {
    if (type === 'block') {
      /**
       * Block components are nested into groups.
       * @example block > titles > title1
       */

      emptyStaleBlockRefs();

      /**
       * Build render record of block type items.
       */

      renderRecord = {} as RenderRecordGrouped;

      const blockConfig = config as PortableTextConfigBlock;
      if (blockConfig) {
        // iterate through the groups
        for (const groupValues of Object.entries(blockConfig)) {
          // groupValues[0] is the key, groupValues[1] is the array of children
          const group = groupValues[1] as PortableTextConfigBlockType;

          // iterate through the items in a group
          for (const [componentKey, componentValue] of Object.entries(group)) {
            const componentItem = componentValue as PortableTextConfigProp;

            if (typeof componentItem !== 'undefined') {
              renderRecord[componentKey] = renderItem;
            }
          }
        }
      } else {
        console.warn('Content Error', type, data, config, options);
      }
    } else {
      /**
       * The remaining component types have a flat hierarchy; they do not use groups
       * @example marks > ul
       */

      emptyStaleFlatRefs();

      /**
       * Build render record of flat type items (everything but blocks).
       */

      renderRecord = {} as RenderRecordFlat;

      const flatConfig = config as PortableTextConfigFlatHierarchy;
      if (flatConfig) {
        for (const [componentIterator, componentValue] of Object.entries(
          flatConfig,
        )) {
          const componentKey: keyof RenderRecord =
            componentIterator as keyof PortableTextConfigFlatHierarchy;
          const componentItem = componentValue as PortableTextConfigProp;
          if (typeof componentItem !== 'undefined') {
            renderRecord[componentKey] = renderItem;
          }
        }
      } else {
        console.warn('Content Error', type, data, config, options);
      }
    }

    return renderRecord;
  }

  return {};
};

export default makeComponent;

// additional typed variants to simplify usage

// blocks (styles)

export const makeBlockComponent = (
  data: PortableTextConfigProp,
  config: PortableTextConfig,
  options: PortableTextOptions,
): PortableTextBlockRecord => {
  return makeComponent(
    'block',
    data,
    config,
    options,
  ) as PortableTextBlockRecord;
};

// lists (bullets, numbers)

export const makeListComponent = (
  data: PortableTextConfigProp,
  config: PortableTextConfig,
  options: PortableTextOptions,
): PortableTextListRecord => {
  return makeComponent('list', data, config, options) as PortableTextListRecord;
};

// list items

export const makeListItemComponent = (
  data: PortableTextConfigProp,
  config: PortableTextConfig,
  options: PortableTextOptions,
): PortableTextListItemRecord => {
  return makeComponent(
    'listItem',
    data,
    config,
    options,
  ) as PortableTextListItemRecord;
};

// marks (inline styles - strong, em, links)

export const makeMarksComponent = (
  data: PortableTextConfigProp,
  config: PortableTextConfig,
  options: PortableTextOptions,
): PortableTextMarksRecord => {
  return makeComponent(
    'marks',
    data,
    config,
    options,
  ) as PortableTextMarksRecord;
};

// custom block-level components (types)

export const makeTypesComponent = (
  data: PortableTextConfigProp,
  config: PortableTextConfig,
  options: PortableTextOptions,
): PortableTextTypesRecord => {
  return makeComponent(
    'types',
    data,
    config,
    options,
  ) as PortableTextTypesRecord;
};
