import addToRefArray from '~/utils/addToRefArray';

import { Footnote, PortableTextRenderProps } from '../../../PortableText.types';
import styles from './FootnoteMark.module.css';

const FootnoteMark = ({
  children,
  className,
  refs,
  value,
}: PortableTextRenderProps) => {
  const footnoteValue = value as unknown as Footnote;
  return (
    <span className={className}>
      {children}
      <a
        href={`#footnote-${footnoteValue.indexOnPage}`}
        ref={(element) =>
          refs && element && addToRefArray({ element, refArray: refs })
        }
        id={`footnote-origin-${footnoteValue.indexOnPage}`}
      >
        <sup className={styles.superscript}>{footnoteValue.indexOnPage}</sup>
      </a>
    </span>
  );
};

export default FootnoteMark;
