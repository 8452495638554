import Link from '~/components/atoms/Link/Link';
import { CMSLinkProps } from '~/components/atoms/Link/Link.types';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { PortableTextRenderProps } from '../../../PortableText.types';

const LinkInternalMark = ({
  className,
  refs,
  text,
  value,

  children,
}: PortableTextRenderProps) => {
  return (
    <span
      className={cn(styles.link, className)}
      ref={(element: HTMLElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {/* TODO: cast is temporary, need to fix typing */}
      {value?.url && text && <Link to={value as CMSLinkProps}>{children}</Link>}
      {(!value || !value.url) && <>{text || children}</>}
    </span>
  );
};

export default LinkInternalMark;
