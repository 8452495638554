import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import { PortableTextRenderProps } from '../../../PortableText.types';
import styles from './Table.module.css';
import { PortableTextTableProps } from './Table.types';

const PortableTextTable = (props: PortableTextRenderProps) => {
  const { className, refs, value } = props as PortableTextTableProps;

  return (
    <div
      className={cn(className, styles.table)}
      ref={(element: HTMLDivElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {value.markdownString && (
        <ReactMarkdown
          className={styles.tableInner}
          remarkPlugins={[remarkGfm]}
        >
          {value.markdownString}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default PortableTextTable;
