import { PortableTextRenderProps } from '../../PortableText.types';
import { PortableTextConfigMarks } from '../../PortableTextConfig.types';
import DriftMark from './DriftMark/DriftMark';
import { DriftMarkProps } from './DriftMark/DriftMark.types';
import EmMark from './EmMark/EmMark';
import FootnoteMark from './FootnoteMark/FootnoteMark';
import LinkMark from './LinkMark/LinkMark';
import MailtoMark from './MailtoMark/MailtoMark';
import OpenConsent from './OpenConsentMark/OpenConsentMark';
import OpenQuote from './OpenQuote/OpenQuote';
import StrongMark from './StrongMark/StrongMark';
import UnderlineMark from './UnderlineMark/UnderlineMark';

const configMarks: PortableTextConfigMarks = {
  em: {
    render: (props: PortableTextRenderProps) => (
      <EmMark {...props}>{props.children}</EmMark>
    ),
  },
  u: {
    render: (props: PortableTextRenderProps) => (
      <UnderlineMark {...props}>{props.children}</UnderlineMark>
    ),
  },
  strong: {
    render: (props: PortableTextRenderProps) => (
      <StrongMark {...props}>{props.children}</StrongMark>
    ),
  },
  link: {
    render: (props: PortableTextRenderProps) => (
      <LinkMark {...props}>{props.children}</LinkMark>
    ),
  },
  internalLink: {
    render: (props: PortableTextRenderProps) => (
      <LinkMark {...props}>{props.children}</LinkMark>
    ),
  },

  download: {
    render: (props: PortableTextRenderProps) => (
      <LinkMark {...props}>{props.children}</LinkMark>
    ),
  },
  mailTo: {
    render: (props: PortableTextRenderProps) => (
      <MailtoMark {...props}>{props.children}</MailtoMark>
    ),
  },
  openConsent: {
    render: (props: PortableTextRenderProps) => (
      <OpenConsent {...props}>{props.children}</OpenConsent>
    ),
  },
  drift: {
    render: (props: PortableTextRenderProps) => (
      // We cast here because this current type system doesn't allow us to properly type the props
      <DriftMark {...(props as DriftMarkProps)}>{props.children}</DriftMark>
    ),
  },
  footnote: {
    render: (props: PortableTextRenderProps) => (
      <FootnoteMark {...props}>{props.children}</FootnoteMark>
    ),
  },
  openQuote: {
    render: (props: PortableTextRenderProps) => (
      <OpenQuote {...props}>{props.children}</OpenQuote>
    ),
  },
};

export default configMarks;
