import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './OpenConsentButton.module.css';
import { OpenConsentButtonProps } from './OpenConsentButton.types';

const OpenConsentButton = ({
  children,
  className,
  refs,
}: OpenConsentButtonProps) => {
  return (
    <button
      className={cn(className, styles.openContentButton)}
      ref={(element: HTMLButtonElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
      onClick={(e) => {
        e.preventDefault();
        if (window.adobePrivacy) window.adobePrivacy.showPreferenceCenter();
      }}
    >
      {children}
    </button>
  );
};

export default OpenConsentButton;
