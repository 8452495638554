import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { PortableTextRenderProps } from '../../../PortableText.types';

const Underline = ({ children, className, refs }: PortableTextRenderProps) => {
  return (
    <u
      className={cn(styles.u, className)}
      ref={(element: HTMLElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {children}
    </u>
  );
};

export default Underline;
