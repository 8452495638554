import { PortableTextRenderProps } from '../../PortableText.types';
import { PortableTextConfigList } from '../../PortableTextConfig.types';
import BulletList from './BulletList/BulletList';
import NumberList from './NumberList/NumberList';

const configList: PortableTextConfigList = {
  bullet: {
    render: (value: PortableTextRenderProps) => (
      <BulletList {...value}>{value.children}</BulletList>
    ),
  },
  number: {
    render: (value: PortableTextRenderProps) => (
      <NumberList {...value}>{value.children}</NumberList>
    ),
  },
};

export default configList;
