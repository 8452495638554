import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import DriftButton from '~/components/atoms/Buttons/Ctas/DriftButton/DriftButton';
import OutlookButton from '~/components/atoms/Buttons/Ctas/OutlookButton/OutlookButton';
import { cn, getUniqueId } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import { PortableTextRenderProps } from '../../../PortableText.types';
import { AnyPortableTextButton } from '../Types.types';
import styles from './ButtonGroup.module.css';
import { PortableTextButtonGroupProps } from './ButtonGroup.types';

const ButtonGroup = (props: PortableTextRenderProps) => {
  const { className, refs, value } = props as PortableTextButtonGroupProps;
  const { buttonGroup } = value;

  return (
    <div
      className={cn(styles.buttonGroup, className)}
      ref={(element: HTMLDivElement) =>
        refs && addToRefArray({ element, refArray: refs })
      }
    >
      {buttonGroup &&
        buttonGroup.flatMap((button: AnyPortableTextButton) => {
          const buttonClassName = cn(button.button?.className, styles.button);
          switch (button._type) {
            case 'button':
              // We have to create this props variable in the switch case to ensure Typescript will infer the right type
              const buttonProps = { ...button.button };
              delete buttonProps.className;
              return button?.button
                ? [
                    <Button
                      key={getUniqueId()}
                      {...buttonProps}
                      className={buttonClassName}
                      asNonInteractive={button?.interactionRemoved}
                    >
                      {buttonProps.label}
                    </Button>,
                  ]
                : [];
            case 'driftButton':
              // We have to create this props variable in the switch case to ensure Typescript will infer the right type
              const driftButtonProps = { ...button.button };
              delete driftButtonProps.className;
              return (
                <DriftButton
                  key={getUniqueId()}
                  {...driftButtonProps}
                  className={buttonClassName}
                  asNonInteractive={button?.interactionRemoved}
                  driftConfiguration={button.button.driftConfiguration}
                />
              );
            case 'outlookButton':
              // We have to create this props variable in the switch case to ensure Typescript will infer the right type
              const outlookButtonProps = { ...button.button };
              delete outlookButtonProps.className;
              return (
                <OutlookButton
                  key={getUniqueId()}
                  {...outlookButtonProps}
                  className={buttonClassName}
                  asNonInteractive={button?.interactionRemoved}
                />
              );
          }
        })}
    </div>
  );
};

export default ButtonGroup;
