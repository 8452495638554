import { default as MoleculeGraphic } from '~/components/atoms/Graphic/Graphic';
import addToRefArray from '~/utils/addToRefArray';

import { PortableTextRenderProps } from '../../../PortableText.types';
import { PortableTextGraphicProps } from './Graphic.types';

const Graphic = (props: PortableTextRenderProps) => {
  const { className, value, refs } = props as PortableTextGraphicProps;
  const { logo } = value;
  return (
    <div
      className={className}
      ref={(element: HTMLDivElement) =>
        refs && addToRefArray({ element, refArray: refs })
      }
    >
      <MoleculeGraphic {...logo} />
    </div>
  );
};

export default Graphic;
