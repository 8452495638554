import ButtonBase from '~/components/atoms/Buttons/Ctas/ButtonBase';
import useDriftButton from '~/components/atoms/Buttons/Ctas/DriftButton/useDriftButton';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import portableTextStyles from '../../../PortableText.module.css';
import styles from './DriftMark.module.css';
import { DriftMarkProps } from './DriftMark.types';

const DriftMark = (props: DriftMarkProps) => {
  const { onClick, to } = useDriftButton(props.value.button.driftConfiguration);

  return (
    <ButtonBase
      ref={(element: HTMLElement) =>
        props.refs &&
        element &&
        addToRefArray({ element, refArray: props.refs })
      }
      onClick={onClick}
      to={to}
      className={cn(portableTextStyles.link, props.className, styles.driftMark)}
    >
      {props.children}
    </ButtonBase>
  );
};

export default DriftMark;
