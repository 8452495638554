import { Children, ReactNode } from 'react';

import { AtomBase } from '~/components/organisms/ModularComponent/ModularComponent.types';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import { PortableTextRenderProps } from '../../../PortableText.types';
import styles from './NumberList.module.css';

type ListChild = ReactNode & AtomBase;

const NumberList = ({ children, className, refs }: PortableTextRenderProps) => {
  const renderClass = cn(styles.numberList, className);

  return (
    <ol
      className={renderClass}
      ref={(element: HTMLOListElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      {Children.map(children, (child: ReactNode) => {
        // listChild is determined by PortableText, it will use a NumberListItem
        const listItem = child as ListChild;
        return <>{listItem}</>;
      })}
    </ol>
  );
};

export default NumberList;
