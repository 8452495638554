'use client';

import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import Button from '../Button/Button';
import { ForwardedButtonRef } from '../Button/Button.types';
import styles from './OutlookButton.module.css';
import { OutlookButtonProps } from './OutlookButton.types';

declare global {
  interface Window {
    // We're accepting any here because Drfit is a third party library loaded independently that doesn't provide types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    outlook?: any;
  }
}

/**
 * A CTA Button that allows the user to either open the Outlook pop-up if available, or opens a new tab with the appropriate
 * Outlook page to interact with customer service
 * @param label The label within the button
 * @param desktopLink The link for desktop devices
 * @param mobileLink The link for mobile devices
 * @param label The label within the button
 */
const OutlookButton = (
  props: OutlookButtonProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  const { label, children, customTrackingEvent, mobileLink, desktopLink } =
    props;

  const $button = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      $button,
    }),
    [],
  );

  const trackingEvent = [customTrackingEvent];

  return (
    <>
      <Button
        {...props}
        to={{ url: desktopLink, type: 'external' }}
        ref={$button}
        customTrackingEvent={trackingEvent}
        className={styles.desktop}
      >
        {children || label}
      </Button>
      <Button
        {...props}
        to={{ url: mobileLink, type: 'external' }}
        ref={$button}
        customTrackingEvent={trackingEvent}
        className={styles.mobile}
      >
        {children || label}
      </Button>
    </>
  );
};

export default forwardRef(OutlookButton);
