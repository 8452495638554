import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { PortableTextRenderProps } from '../../../PortableText.types';

const MailtoMark = ({
  className,
  refs,
  value,
  children,
}: PortableTextRenderProps) => {
  if (!value?.url) {
    return null;
  }

  return (
    <span
      className={cn(styles.link, className)}
      ref={(element: HTMLElement) =>
        refs && element && addToRefArray({ element, refArray: refs })
      }
    >
      <a href={`mailto:${value.url}`}>{children}</a>
    </span>
  );
};

export default MailtoMark;
