'use client';

import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import Button from '../Button/Button';
import { ForwardedButtonRef } from '../Button/Button.types';
import { DriftButtonProps } from './DriftButton.types';
import useDriftButton from './useDriftButton';

declare global {
  interface Window {
    // We're accepting any here because Drfit is a third party library loaded independently that doesn't provide types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drift?: any;
  }
}

/**
 * A CTA Button that allows the user to either open the Drift pop-up if available, or opens a new tab with the appropriate
 * Drift page to interact with customer service
 * @param driftConfiguration The base configuration to interact with Drift
 * @param driftConfiguration.interactionId The ID of the Drift interaction (which popup it'll open if available)
 * @param driftConfiguration.fallbackLink Link for when Drift is not available in the window
 * @param label The label within the button
 */
const DriftButton = (
  props: DriftButtonProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  const { driftConfiguration, label, children, customTrackingEvent } = props;

  const $button = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      $button,
    }),
    [],
  );

  const { onClick, to } = useDriftButton(driftConfiguration);

  if (!driftConfiguration.fallbackLink) return null;

  const trackingEvent = [
    customTrackingEvent,
    driftConfiguration.customTrackingEvent,
  ];

  return (
    <Button
      {...props}
      to={to}
      ref={$button}
      onClick={onClick}
      customTrackingEvent={trackingEvent}
    >
      {children || label}
    </Button>
  );
};

export default forwardRef(DriftButton);
