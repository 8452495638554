import { cloneElement, useRef } from 'react';

import SplitText from '~/components/molecules/SplitText/SplitText';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from '../../../PortableText.module.css';
import { BlockBaseProps } from './BlockBase.types';

const BlockBase = (props: BlockBaseProps) => {
  const {
    children,
    className,
    refs,
    styleKey,
    groupStyleKey,
    tagName,
    wordRefs,
    letterRefs,
    index,
    value,
  } = props;
  const RenderTag: string = tagName || 'div';
  const defaultStyle = styleKey ? styles[styleKey] : '';
  const renderClass = cn(defaultStyle, className) || null;
  // Note: empty attributes don't make sense but it's required by the type checker
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attributes: any = {};

  const splitLetters = typeof letterRefs !== 'undefined';
  const splitWords = typeof wordRefs !== 'undefined';

  const $element = useRef<HTMLElement>();

  const shouldSplit = splitWords || splitLetters;

  const renderSplitText = (i: number, text: string, markType?: string) => {
    return (
      <SplitText
        key={i}
        wordRefs={shouldSplit ? wordRefs : undefined}
        letterRefs={shouldSplit && splitLetters ? letterRefs : undefined}
        index={index}
        groupStyleKey={groupStyleKey}
        category={value?._type}
        parentRef={$element}
        markType={markType}
      >
        {text}
      </SplitText>
    );
  };

  const text = Array.isArray(children)
    ? children
        ?.map((child, i) => {
          if (typeof child === 'string') {
            // if it's a plain string
            return renderSplitText(i, child);
          } else {
            const { text, markType } = child.props;

            // if it's a mark it will be a react node
            return cloneElement(child, {
              children: renderSplitText(i, text, markType),
            });
          }
        })
        .flat()
    : children;

  return (
    <RenderTag
      className={renderClass}
      ref={(element: HTMLElement) => {
        $element.current = element;
        return refs && element && addToRefArray({ element, refArray: refs });
      }}
      {...attributes}
    >
      {text}
    </RenderTag>
  );
};

export default BlockBase;
