import { PortableTextRenderProps } from '../../PortableText.types';
import { PortableTextConfigTypes } from '../../PortableTextConfig.types';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import Graphic from './Graphic/Graphic';
import Table from './Table/Table';

const configTypes: PortableTextConfigTypes = {
  'block.buttonGroup': {
    render: (value: PortableTextRenderProps) => (
      <ButtonGroup {...value}>{value.children}</ButtonGroup>
    ),
  },

  'block.graphic': {
    render: (value: PortableTextRenderProps) => <Graphic {...value} />,
  },

  'block.table': {
    render: (value: PortableTextRenderProps) => <Table {...value} />,
  },
};

export default configTypes;
