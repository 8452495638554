import { PortableTextRenderProps } from '../../../PortableText.types';
import OpenConsentButton from './OpenConsentButton';

const OpenConsent = ({
  children,
  className,
  refs,
}: PortableTextRenderProps) => {
  return (
    <OpenConsentButton className={className} refs={refs}>
      {children}
    </OpenConsentButton>
  );
};

export default OpenConsent;
